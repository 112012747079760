const selectors = {
    self: ".filter",
    inputName: ".filter-name",
    textFrom: ".from-input",
    textTo: ".to-input",
    inputFrom: ".from-slider",
    inputTo: ".to-slider",
    inputTypeProduct: "input[name='typeProduct[]']",
    inputHour: "input[name='hour[]']",
    inputDuration: "input[name='duration[]']",
    inputName: "input[name='name']",
    productList: ".products-list",
    pagination: ".page-paginate",
    select: ".page-select",
    pageSpinner: ".page-spinner",
    dNone: "d-none",
    dFlex: "d-flex",
};
// Variável para armazenar o timer do debounce
let debounceTimer;

function renderPagination($element, data) {
    const pagination = $(".pagination");
    pagination.empty();

    // Atualiza a contagem de itens
    $("#first-item").text(data.meta.from);
    $("#last-item").text(data.meta.to);
    $("#total-items").text(data.meta.total);

    const currentPage = data.meta.current_page;
    const lastPage = data.meta.last_page;

    // Verifica se há mais de uma página
    if (lastPage <= 1) {
        return;
    }

    // Previous Page Link
    if (currentPage === 1) {
        pagination.append(
            '<li class="page-item disabled" aria-disabled="true"><span class="page-link">&lsaquo;</span></li>'
        );
    } else {
        pagination.append(
            `<li class="page-item"><button type="button" class="page-link" data-page="${
                currentPage - 1
            }">&lsaquo;</button></li>`
        );
    }

    // First Page Link
    pagination.append(
        `<li class="page-item ${
            currentPage === 1 ? "active" : ""
        }"><button type="button" class="page-link" data-page="1">1</button></li>`
    );
    if (currentPage > 4) {
        pagination.append(
            '<li class="page-item disabled"><span class="page-link">...</span></li>'
        );
    }

    // Middle Pages
    const startPage = Math.max(currentPage - 2, 2);
    const endPage = Math.min(currentPage + 2, lastPage - 1);

    for (let page = startPage; page <= endPage; page++) {
        pagination.append(
            `<li class="page-item ${
                page === currentPage ? "active" : ""
            }"><button type="button" class="page-link" data-page="${page}">${page}</button></li>`
        );
    }

    // Last Page Link
    if (currentPage < lastPage - 3) {
        if (currentPage < lastPage - 4) {
            pagination.append(
                '<li class="page-item disabled"><span class="page-link">...</span></li>'
            );
        }
        pagination.append(
            `<li class="page-item"><button type="button" class="page-link" data-page="${
                lastPage - 1
            }">${lastPage - 1}</button></li>`
        );
        pagination.append(
            `<li class="page-item"><button type="button" class="page-link" data-page="${lastPage}">${lastPage}</button></li>`
        );
    } else if (currentPage >= lastPage - 3 && currentPage < lastPage) {
        pagination.append(
            `<li class="page-item ${
                currentPage === lastPage ? "active" : ""
            }"><button type="button" class="page-link" data-page="${lastPage}">${lastPage}</button></li>`
        );
    }

    // Next Page Link
    if (currentPage !== lastPage) {
        pagination.append(
            '<li class="page-item disabled" aria-disabled="true"><span class="page-link">&rsaquo;</span></li>'
        );
    } else {
        pagination.append(
            `<li class="page-item"><button type="button" class="page-link" data-page="${
                currentPage + 1
            }">&rsaquo;</button></li>`
        );
    }

    // Adiciona evento de clique para os botões de paginação
    $(".page-link").on("click", function () {
        if (
            !$(this).closest("li").hasClass("disabled") &&
            !$(this).closest("li").hasClass("active")
        ) {
            const page = $(this).data("page");
            search($element, page);
        }
    });
}

// Função auxiliar para extrair o número da página da URL
function getPageFromUrl(url) {
    const params = new URLSearchParams(url.split("?")[1]);
    return params.get("page");
}

function getForm($element) {
    $element.find(selectors.inputTypeProduct).on("click", function () {
        search($element);
    });

    $element.find(selectors.inputHour).on("click", function () {
        search($element);
    });

    $element.find(selectors.inputDuration).on("click", function () {
        search($element);
    });

    $element.find(selectors.inputName).on("keyup", function () {
        debounceSearch(() => {
            search($element); // Chama a função de busca com o elemento específico após resolver a promessa e o debounce
        }, 300); // Ajuste o tempo de debounce conforme necessário
    });

    $(selectors.select).on("change", function (event) {
        debounceSearch(() => {
            search($element); // Chama a função de busca com o elemento específico após resolver a promessa e o debounce
        }, 300); // Ajuste o tempo de debounce conforme necessário
    });
}

function mountRequest($element) {
    return {
        backIn: $element.data("back"),
        company: $element.data("company"),
        destiny: $element.data("destiny"),
        date: $element.data("date"),
        order: $(`${selectors.select} option:selected`).val(),
        name: $element.find(selectors.inputName).val(),
        fromSlider: $element.find(selectors.inputFrom).val(),
        toSlider: $element.find(selectors.inputTo).val(),
        typeProduct: $element
            .find(selectors.inputTypeProduct + ":checked")
            .map(function () {
                return $(this).val();
            })
            .get(),
        hour: $element
            .find(selectors.inputHour + ":checked")
            .map(function () {
                return $(this).val();
            })
            .get(),
        duration: $(selectors.inputDuration + ":checked")
            .map(function () {
                return $(this).val();
            })
            .get(),
    };
}

function mountCard(item) {
    return `
        <div class="col-12 col-md-6 col-xl-12">
            <div class="products-cardv2 w-100 mb-4">
                <div class="card w-100 d-flex flex-xl-row">
                    <img src="${
                        item.file.length > 0
                            ? item.file[0].path
                            : "/images/image-not-found.jpg"
                    }
                    " class="card-img-top w-auto" loading="lazy" alt="${
                        item.name
                    }" />
                    <div class="w-100 d-xl-flex justify-content-between">
                        <div class="card-body pb-3">
                            <h5 class="card-title">${item.name}</h5>
                            <h2 class="card-subtitle badge rounded-pill text-white">Rio Grande do Norte</h2>
                            <div class="card-text pt-3 d-none d-xl-block">
                                ${item.description.slice(0, 375)}...
                            </div>
                        </div>
                        <div class="card-footer border-0 d-xl-flex align-items-center flex-column justify-content-center pt-xl-4">
                            <p class="mb-2 card-text text-xl-center pt-xl-4">A partir de</p>
                            <p class="d-flex align-items-center m-0">
                                <span class="card-amount">${
                                    item.amount
                                }</span> <small class="card-text ps-2"> Por pessoa</small>
                            </p>
                            <a href="/tours/${
                                item.slug
                            }" class="btn card-btn mt-2">Aproveitar oferta</a>
                            <p class="card-text text-xl-center pt-3 mb-0">Passeio realizado por:</p>
                            <div class="w-100 d-flex align-items-center justify-content-xl-center pb-2">
                                <img src="${item.productble.file.path}"
                                    class="page-company-card-img img-fluid rounded-circle" alt="${
                                        item.productble.name
                                    }" />
                                <a href="/company/${
                                    item.productble.slug
                                }" class="ms-2">
                                    <p class="page-company-card-title m-0 text-capitalize">${
                                        item.productble.name
                                    }</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>`;
}

function renderProducts($element, data) {
    const productList = $(selectors.productList);
    productList.empty();
    data.forEach((item) => {
        productList.append(mountCard(item));
    });
}

function search($element, page = 1) {
    const requestData = {
        page: page,
        ...mountRequest($element),
    };

    // Faz a requisição AJAX para o endpoint filterProducts
    $.ajax({
        url: "/filter-product", // Altere conforme o seu endpoint no Laravel
        type: "POST",
        headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        },
        data: requestData,
        success: function (response) {
            if (response.data.length > 0) {
                renderProducts($element, response.data);
                renderPagination($element, response);
                $(selectors.pageSpinner).addClass(selectors.dNone);
            } else {
                $(selectors.pageSpinner).addClass(selectors.dNone);
                $(selectors.productList).html(`<div class='page-notfound'>
                    <div class='row py-5'>
                        <svg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-frown' style='color: #008BD0;''><circle cx='12' cy='12' r='10'></circle><path d='M16 16s-1.5-2-4-2-4 2-4 2'></path><line x1='9' y1='9' x2='9.01' y2='9'></line><line x1='15' y1='9' x2='15.01' y2='9'></line></svg>
                        <h2 class='page-title text-center mt-2 fs-2'>
                            Nada aqui!
                        </h2>
                    </div>
                </div>`);
            }
        },
        error: function (xhr, status, error) {
            console.error(error); // Trata erros caso ocorram durante a requisição
        },
    });
}

// Função para controlar o slider 'de' e atualizar a interface
function controlFromSlider(fromSlider, toSlider, fromInput) {
    return new Promise((resolve, reject) => {
        var from = parseInt(fromSlider.val());
        var to = parseInt(toSlider.val());
        setToggleAccessible(fromSlider);
        fillSlider(fromSlider, toSlider, "#C6C6C6", "#25daa5", toSlider); // Atualiza a aparência do slider
        if (from > to) {
            fromSlider.val(to); // Garante que o slider 'de' não ultrapasse o slider 'até'
            fromInput.text(to); // Atualiza o valor exibido
        } else {
            fromInput.text(from); // Atualiza o valor exibido
        }
        resolve(); // Resolve a promessa após o controle do slider 'de'
    });
}

// Função para controlar o slider 'até' e atualizar a interface
function controlToSlider(fromSlider, toSlider, toInput) {
    return new Promise((resolve, reject) => {
        var from = parseInt(fromSlider.val());
        var to = parseInt(toSlider.val());
        fillSlider(fromSlider, toSlider, "#C6C6C6", "#25daa5", toSlider); // Atualiza a aparência do slider
        setToggleAccessible(toSlider); // Configura a acessibilidade com base no valor do slider 'até'
        if (from <= to) {
            toSlider.val(to); // Atualiza o valor do slider 'até'
            toInput.text(`R$ ${to},00`); // Atualiza o valor exibido
        } else {
            toInput.text(from); // Atualiza o valor exibido
            toSlider.val(from); // Atualiza o valor do slider 'até'
        }
        resolve(); // Resolve a promessa após o controle do slider 'até'
    });
}

// Função para chamar a função search após um pequeno intervalo após a última interação com o slider
function debounceSearch(callback, delay) {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(callback, delay);
}

// Função para preencher o slider com cores e gradientes
function fillSlider(from, to, sliderColor, rangeColor, controlSlider) {
    var rangeDistance = controlSlider.attr("max") - controlSlider.attr("min");
    var fromPosition = from.val() - controlSlider.attr("min");
    var toPosition = to.val() - controlSlider.attr("min");
    var rangePosition = fromPosition; // Inicia a bolinha na parte inferior

    controlSlider.css(
        "background",
        `
            ${sliderColor}  `
    );

    //   controlSlider.css(
    //       "background",
    //       `linear-gradient(
    //         to right,
    //         ${sliderColor} 0%,
    //         ${sliderColor} ${(rangePosition / rangeDistance) * 100}%,
    //         ${rangeColor} ${(rangePosition / rangeDistance) * 100}%,
    //         ${rangeColor} ${(toPosition / rangeDistance) * 100}%,
    //         ${sliderColor} ${(toPosition / rangeDistance) * 100}%,
    //         ${sliderColor} 100%)`
    //   );
}

// Função para ajustar a acessibilidade com base no valor do slider 'até'
function setToggleAccessible(currentTarget) {
    var toSlider = $("#toSlider");
    if (Number(currentTarget.val()) <= 0) {
        toSlider.css("z-index", 2);
    } else {
        toSlider.css("z-index", 0);
    }
}

// Função de inicialização da página
function initSearch() {
    $element = $(selectors.self);

    // Seleciona os elementos DOM relevantes dentro do contexto do filtro
    const fromSlider = $element.find(selectors.inputFrom);
    const toSlider = $element.find(selectors.inputTo);
    const fromInput = $element.find(selectors.textFrom);
    const toInput = $element.find(selectors.textTo);

    // Configura os eventos de input nos sliders para chamar suas respectivas funções de controle
    fromSlider.on("input", function () {
        controlFromSlider(fromSlider, toSlider, fromInput).then((response) => {
            debounceSearch(() => {
                search($element); // Chama a função de busca com o elemento específico após resolver a promessa e o debounce
            }, 300); // Ajuste o tempo de debounce conforme necessário
        });
    });

    toSlider.on("input", function () {
        controlToSlider(fromSlider, toSlider, toInput).then(() => {
            debounceSearch(() => {
                search($element); // Chama a função de busca com o elemento específico após resolver a promessa e o debounce
            }, 300); // Ajuste o tempo de debounce conforme necessário
        });
    });

    // Inicializa o slider com a bolinha na parte inferior
    fillSlider(fromSlider, toSlider, "#C6C6C6", "#25daa5", toSlider);

    search($element);
    getForm($element);
}

// Aguarda o documento estar pronto antes de iniciar as configurações
$(document).ready(function () {
    initSearch();
});
