$(document).on("dataSavedMaxGuest", function () {
    const maxGuests = parseInt(localStorage.getItem("maxGuest")) || 0;

    // Função para atualizar os botões de mais/menos
    function updateButtons() {
        $(".guest-type").each(function () {
            const $guestType = $(this);
            const value = parseInt($guestType.find(".value").text());
            const $lessButton = $guestType.find(".less");
            const $moreButton = $guestType.find(".more");

            $lessButton.prop("disabled", value <= 0);
            $moreButton.prop(
                "disabled",
                getTotalGuests() >= maxGuests || value >= maxGuests
            );
        });
    }

    // Função para calcular o total de convidados
    function getTotalGuests() {
        let total = 0;
        $(".value").each(function () {
            total += parseInt($(this).text());
        });
        return total;
    }

    // Função para atualizar o valor do input hidden
    function updateInput($guestType) {
        const value = $guestType.find(".value").text();
        $guestType.find("input[type=hidden]").val(value);
    }

    // Função para manipular cliques nos botões
    function handleClick() {
        const $button = $(this);
        const $guestType = $button.closest(".guest-type");
        const $valueElement = $guestType.find(".value");
        let value = parseInt($valueElement.text());

        if ($button.hasClass("less") && value > 0) {
            value -= 1;
        } else if (
            $button.hasClass("more") &&
            getTotalGuests() < maxGuests &&
            value < maxGuests
        ) {
            value += 1;
        } else {
            return;
        }

        // Dispara evento adicional baseado no valor
        if (value === 0) {
            $(document).trigger("valueIsZero", [$guestType]);
        } else if (value > 0) {
            $(document).trigger("valueGreaterThanOne", [$guestType]);
        }

        $valueElement.text(value);
        updateButtons();
        updateInput($guestType);
    }

    // Função para resetar os inputs
    function resetInputs() {
        $(".guest-type").each(function () {
            $(this).find(".value").text("0");
            updateInput($(this));
        });
    }

    // Remove eventos antigos
    $(document).off("click", "button");

    // Adiciona novo evento de clique
    $(document).on("click", "button", handleClick);

    // Resetar inputs e atualizar a UI
    resetInputs();
    updateButtons();
});
