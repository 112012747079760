const selectors = {
    self: ".search",
    datepicker: "input[name=date]",
    dateDay: ".datepicker--cell-day",
    selectedDate: "-selected-", // Classe para data selecionada
    enabledDate: "-enabled-", // Classe para datas habilitadas
};

function initializeDatePicker($element, enabledDates) {
    const formatDate = "dd/mm/yyyy";

    $element.find(selectors.datepicker).datepicker({
        language: {
            days: [
                "Domingo",
                "Segunda",
                "Terça",
                "Quarta",
                "Quinta",
                "Sexta",
                "Sábado",
            ],
            daysShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
            daysMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
            months: [
                "Janeiro",
                "Fevereiro",
                "Março",
                "Abril",
                "Maio",
                "Junho",
                "Julho",
                "Agosto",
                "Setembro",
                "Outubro",
                "Novembro",
                "Dezembro",
            ],
            monthsShort: [
                "Jan",
                "Fev",
                "Mar",
                "Abr",
                "Mai",
                "Jun",
                "Jul",
                "Ago",
                "Set",
                "Out",
                "Nov",
                "Dez",
            ],
            today: "Hoje",
        },
        minDate: new Date(),
        firstDay: 0,
        autoClose: true,
        dateFormat: formatDate,
    });
}

function DatePicker() {
    const $element = $(selectors.self);
    initializeDatePicker($element);
}

export default DatePicker;
