import { compileString } from "sass";
import httpRequest from "../api/httpRequest";

const selectors = {
    self: ".page-search-form",
    datePicker: ".date-picker",
    id: "input[name=id]",
    date: "input[name=date]",
    hour: "select[name=hour]",
    adult: "input[name=adult]",
    lapchild: "input[name=lapChild]",
    child: "input[name=child]",
    elderly: "input[name=elderly]",
    form: ".page-search-form",
    btnSubmit: ".page-search-form-button",
};

var selectedDate = false;

function getDate($element) {
    const product = $element.find(selectors.id).val();
    const url = `/api/product/dates/${product}`;

    httpRequest
        .getRequest(url)
        .then((response) => {
            if (response) {
                initDatePicker($element, response);
            } else {
                console.error("Failed to fetch date data");
            }
        })
        .catch((error) => {
            console.error("HTTP request failed: ", error);
        });
}

function getAllHours($element, data, $date) {
    const uniqueHours = [];
    const $select = $element.find(selectors.hour);
    $select.parent().removeClass("d-none");

    for (const category in data) {
        data[category].forEach((item) => {
            if (item.date === $date && !uniqueHours.includes(item.time)) {
                uniqueHours.push(item.time);
            }
        });
    }

    uniqueHours.sort((a, b) => a.localeCompare(b, "en", { numeric: true }));

    if (uniqueHours.length > 0) {
        $select.prop("disabled", false);
        $select.empty();
        $select.append("<option value=''>Selecione</option>");
        uniqueHours.forEach((hour) => {
            $select.append(`<option value="${hour}">${hour}</option>`);
        });

        filterSelect($element, data, $date);
    }
}

function emptySelect($element, select) {
    const $select = $element.find(select);
    $select.prop("disabled", true);
    $select.empty();
    $select.append("<option>Selecione</option>");
}

function eventMaxGuest(data) {
    console.log(data);
    localStorage.setItem("maxGuest", data);
    $(document).trigger("dataSavedMaxGuest");
}
function filterSelect($element, data, $date) {
    emptySelect($element, selectors.lapchild);
    emptySelect($element, selectors.child);
    emptySelect($element, selectors.adult);
    emptySelect($element, selectors.elderly);

    console.log(data["Criança de Colo"]);

    $element.find(selectors.hour).on("change", () => {
        const hour = $element.find(`${selectors.hour} option:selected`).val();
        const filteredData = {
            // "Criança de Colo": data["Criança de Colo"].filter(
            //     (item) => item.date === $date && item.time === hour
            // ),
            "Criança de 2 a 12 anos incompletos (com assento)": data[
                "Criança de 2 a 12 anos incompletos (com assento)"
            ].filter((item) => item.date === $date && item.time === hour),
            Adulto: data["Adulto"].filter(
                (item) => item.date === $date && item.time === hour
            ),
            // Idoso: data["Idoso"].filter(
            //     (item) => item.date === $date && item.time === hour
            // ),
        };

        console.log(filteredData);

        // if (filteredData["Criança de Colo"].length > 0) {
        //     template(
        //         $element,
        //         selectors.lapchild,
        //         filteredData["Criança de Colo"],
        //         $date
        //     );

        // const filtered = filteredData["Criança de Colo"].filter(
        //     (item) => item.date === $date
        // );

        // maxGuests = filtered;
        //     $element.find(selectors.lapchild).parent().removeClass("d-none");
        //     $element.find(selectors.btnSubmit).parent().removeClass("d-none");
        // } else {
        //     $element.find(selectors.lapchild).empty();
        //     $element
        //         .find(selectors.lapchild)
        //         .append("<option value=''>Selecione</option>");
        //     $element.find(selectors.lapchild).parent().addClass("d-none");
        // }

        if (
            filteredData["Criança de 2 a 12 anos incompletos (com assento)"]
                .length > 0
        ) {
            template(
                $element,
                selectors.child,
                filteredData[
                    "Criança de 2 a 12 anos incompletos (com assento)"
                ],
                $date
            );

            const filtered = filteredData[
                "Criança de 2 a 12 anos incompletos (com assento)"
            ].filter((item) => item.date === $date);

            eventMaxGuest(filtered[0].quantity);

            $element
                .find(selectors.child)
                .parent()
                .parent()
                .removeClass("d-none");
        } else {
            $element.find(selectors.child).parent().parent().addClass("d-none");
            $element.find(selectors.child).empty();
            $element
                .find(selectors.child)
                .append("<option value=''>Selecione</option>");
        }

        if (filteredData["Adulto"].length > 0) {
            template($element, selectors.adult, filteredData["Adulto"], $date);

            const filtered = filteredData["Adulto"].filter(
                (item) => item.date === $date
            );

            eventMaxGuest(filtered[0].quantity);

            $element
                .find(selectors.adult)
                .parent()
                .parent()
                .removeClass("d-none");
        } else {
            $element.find(selectors.adult).parent().parent().addClass("d-none");
            $element.find(selectors.adult).empty();
            $element
                .find(selectors.adult)
                .append("<option value=''>Selecione</option>");
        }

        // if (filteredData["Idoso"].length > 0) {
        //     template($element, selectors.elderly, filteredData["Idoso"], $date);
        //    const filtered = filteredData["Idoso"].filter(
        //        (item) => item.date === $date
        //    );

        //    maxGuests = filtered;
        //     $element.find(selectors.elderly).parent().removeClass("d-none");
        //     $element.find(selectors.btnSubmit).parent().removeClass("d-none");
        // } else {
        //     $element.find(selectors.elderly).parent().addClass("d-none");
        //     $element.find(selectors.elderly).empty();
        //     $element
        //         .find(selectors.elderly)
        //         .append("<option value=''>Selecione</option>");
        // }
    });
}

function template($element, select, data, $date) {
    const filtered = data.filter((item) => item.date === $date);

    if (filtered.length > 0) {
        const $select = $element.find(select);
        $select.prop("disabled", false);
        $select.empty();
        $select.append("<option value=''>Selecione</option>");

        for (let index = 1; index <= filtered[0].quantity; index++) {
            $select.append(`<option value="${index}">${index}</option>`);
        }
    } else {
        console.warn(`Nenhum dado encontrado para a data: ${$date}`);
    }
}

function getData($element, $date) {
    const product = $element.find(selectors.id).val();
    $element.find(selectors.date).val($date);
    const url = `/api/product/date/${product}/${$date}`;

    httpRequest
        .getRequest(url)
        .then((response) => {
            console.log(response);
            if (response) {
                getAllHours($element, response, $date);
            } else {
                console.error("Failed to fetch hourly data");
            }
        })
        .catch((error) => {
            console.error("HTTP request failed: ", error);
        });
}

function initDatePicker($element, $dates) {
    const formatDate = "yyyy-mm-dd";

    function isDateEnabled(date) {
        const formattedDate = date.toISOString().split("T")[0];
        return $dates.includes(formattedDate);
    }

    $element.find(selectors.datePicker).datepicker({
        language: {
            days: [
                "Domingo",
                "Segunda",
                "Terça",
                "Quarta",
                "Quinta",
                "Sexta",
                "Sábado",
            ],
            daysShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
            daysMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
            months: [
                "Janeiro",
                "Fevereiro",
                "Março",
                "Abril",
                "Maio",
                "Junho",
                "Julho",
                "Agosto",
                "Setembro",
                "Outubro",
                "Novembro",
                "Dezembro",
            ],
            monthsShort: [
                "Jan",
                "Fev",
                "Mar",
                "Abr",
                "Mai",
                "Jun",
                "Jul",
                "Ago",
                "Set",
                "Out",
                "Nov",
                "Dez",
            ],
            today: "Hoje",
        },
        minDate: 0,
        firstDay: 0,
        dateFormat: formatDate,
        onSelect: function (dateText) {
            getData($element, dateText);
            $element.find(selectors.hour).parent().addClass("d-none");
            $element
                .find(selectors.lapchild)
                .parent()
                .parent()
                .addClass("d-none");
            $element.find(selectors.child).parent().parent().addClass("d-none");
            $element.find(selectors.adult).parent().parent().addClass("d-none");
            $element
                .find(selectors.elderly)
                .parent()
                .parent()
                .addClass("d-none");
            $element.find(selectors.btnSubmit).parent().addClass("d-none");
            selectedDate = true;
        },
        onRenderCell: function (date, cellType) {
            if (cellType === "day") {
                const isEnabled = isDateEnabled(date);
                return {
                    disabled: !isEnabled,
                    classes: isEnabled ? "-enabled-" : "",
                };
            }
        },
    });
}

function submitForm($element) {
    $element.on("submit", function (e) {
        e.preventDefault();

        const $hour = $element.find(`${selectors.hour} option:selected`).val();
        const $lapChild = $element.find(selectors.lapchild).val();
        const $child = $element.find(selectors.child).val();
        const $adult = $element.find(selectors.adult).val();
        const $elderly = $element.find(selectors.elderly).val();

        console.log("Selected values:", {
            $hour,
            $lapChild,
            $child,
            $adult,
            $elderly,
        });

        if (!$hour) {
            if (!selectedDate) {
                Swal.fire({
                    title: "Atenção",
                    text: "Selecione uma data no calendário!",
                    icon: "warning",
                });
            } else {
                Swal.fire({
                    title: "Atenção",
                    text: "Selecione um horário!",
                    icon: "warning",
                });
            }
            return;
        }

        if (
            (!$lapChild || $lapChild === "") &&
            (!$child || $child === "") &&
            (!$adult || $adult === "") &&
            (!$elderly || $elderly === "")
        ) {
            Swal.fire({
                title: "Atenção",
                text: "Selecione a quantidade de pessoas para o passeio!",
                icon: "warning",
            });
            return;
        }

        // Se todos os campos forem preenchidos corretamente, o formulário pode ser enviado
        $element.off("submit").submit();
    });
}

function Product() {
    const $element = $(selectors.self);

    getDate($element);
    submitForm($element);

    $(document).on("valueIsZero", function (event, $guestType) {
        console.log("valueIsZero");
        $element.find(selectors.btnSubmit).parent().addClass("d-none");
    });

    $(document).on("valueGreaterThanOne", function (event, $guestType) {
        $element.find(selectors.btnSubmit).parent().removeClass("d-none");
        console.log("valueGreaterThanOne");
        // Adicione a lógica que deseja executar quando o valor for maior que um
    });
}

export default Product;
