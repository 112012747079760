import $ from 'jquery'
$.noConflict();

const selectors = {
    self: '.filter',
    inputRange: ".input-range",
}

function Filter() {
    $("#slider-range").slider({
        range: true,
        min: 0,
        max: 500,
        values: [ 75, 300 ],
        slide: function(event, ui) {
            $("#amount").val("$" + ui.values[ 0 ] + " - $" + ui.values[1]);
        }
    });

    $("#amount").val("$" + $("#slider-range").slider("values", 0) + " - $" + $("#slider-range").slider("values", 1));
}


$(document).ready(_ => {
    // Filter();
})
