import "./filter";
import "./search";

var selectors = {
    zip: ".zip",
    cnpj: ".cnpj",
    cpf: ".cpf",
    rg: ".rg",
    phone: ".phone",
    fixo: ".fixo",
    amount: ".amount",
    card: ".card-number",
    cardSecurity: ".card-security",
    cardDate: ".card-date",
    hour: ".hour",
};

$(document).ready((_) => {
    $(".destiny-slide").slick({
        dots: true,
        arrows: false,
        infinite: true,
        speed: 300,
        autoplay: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        center: false,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    infinite: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ],
    });

    $(".page-slide").slick({
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        // mobileFirst: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: false,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                },
            },
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ],
    });

    $(".navbar-toggler").on("click", function () {
        $(this).toggleClass("open");
    });

    //Masks
    $(selectors.cnpj).mask("00.000.000/0000-00");
    $(selectors.zip).mask("00.000-000");
    $(selectors.cpf).mask("000.000.000-00");
    $(selectors.rg).mask("000.000.000");
    $(selectors.phone).mask("(00) 0 0000-0000");
    $(selectors.fixo).mask("(00) 0000-0000");
    $(selectors.amount).mask("R$ 000,00", { reverse: false });
    $(selectors.hour).mask("00:00", { reverse: true });
    $(selectors.card).mask("0000 0000 0000 0000");
    $(selectors.cardSecurity).mask("000", { reverse: true });
    $(selectors.cardDate).mask("00/00", { reverse: true });

    $(selectors.hour).mask("00:00", {
        placeholder: "00:00",
        onComplete: function (cep) {
            let parts = cep.split(":");
            if (parts[0].length === 1) {
                $("#hora").val("0" + parts[0] + ":" + parts[1]);
            }
        },
    });
});
